import React from 'react'
import { navigate, Redirect } from '@reach/router'
import { useWindowScroll, useClickAway } from 'react-use'

// Config
import l, { isDgmt, isSef, isSefEmployee } from 'config/localization'
import constants from 'config/constants'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'
import CookieHeader from 'components/CookieHeader'
import FlashMessage from 'components/FlashMessage'
import ProfileMenu from 'components/ProfileMenu'
import Tooltip from 'components/Tooltip'

// Styles
import {
  ChampionBtn,
  DgmtNewPboButton,
  FeedbackIconLink,
  LogoLink,
  MainNavWrapper,
  NavBar,
  NavBarContent,
  NavBarContentItem,
  NavBarRightSection,
  NavLinkItem,
  NavLinkItemWrapper,
  NavLinksWrapper,
  PricingDropdownItem,
  PricingDropdownWrapper,
  ScrollToTop,
  SefNavButton,
  SefNavButtonContents,
  SefNavButtonHint,
  SefNavButtonWrapper,
  SvgIconWrapper,
  TitleText,
  TranslateIconContainer
} from './styles'

// icons
import { PatchQuestionFill } from '@styled-icons/bootstrap/PatchQuestionFill'
import { StarFill } from '@styled-icons/bootstrap'
import { Translate } from '@styled-icons/bootstrap/Translate'

// assets
import logo from 'assets/images/svgs/bp_logo.svg'
import dgmtLogo from 'assets/images/dgmt_logo_blue.jpg'

const SupportIcon = ({ isLoggedIn, menuModalIsOpen }) => {
  if (isLoggedIn) return null
  if (!isDgmt && !isSef) return null
  if (menuModalIsOpen) return null

  return (
    <div
      style={{
        borderRadius: '50%',
        right: '10px',
        width: '35px',
        height: '35px',
        position: 'relative',
        zIndex: '999'
      }}
    >
      <Tooltip
        position="left"
        content="Click here for assistance or to submit feedback."
        displayBlock
        forceShow
      >
        <FeedbackIconLink to={'/dashboard/feedback/new'}>
          <PatchQuestionFill color="white" />
        </FeedbackIconLink>
      </Tooltip>
    </div>
  )
}

const DiscoverDropdown = ({ goTo, discoverDropdownVisible, toggleDiscoverDropdown }) => {
  const ref = React.useRef(null)
  useClickAway(
    ref,
    () => {
      if (discoverDropdownVisible) {
        toggleDiscoverDropdown()
      }
    },
    ['click']
  )

  if (!discoverDropdownVisible) return null

  return (
    <PricingDropdownWrapper ref={ref}>
      <PricingDropdownItem onClick={() => goTo(`/search/challenges`)}>
        Search challenges
      </PricingDropdownItem>

      <PricingDropdownItem onClick={() => goTo(`/search/${l('ROUTE_OPPS')}`)}>
        Search {l('OPPS')}
      </PricingDropdownItem>

      <PricingDropdownItem onClick={() => goTo(`/search/nonprofits`)}>
        Search nonprofits
      </PricingDropdownItem>

      <PricingDropdownItem onClick={() => goTo(`/search/companies`)}>
        Search companies
      </PricingDropdownItem>

      <PricingDropdownItem onClick={() => goTo(`/${l('ROUTE_CAMPS')}`)}>
        Explore {l('CAMPS')}
      </PricingDropdownItem>

      <PricingDropdownItem onClick={() => goTo(`/categories`)}>
        Explore categories
      </PricingDropdownItem>
    </PricingDropdownWrapper>
  )
}

const PricingDropdown = ({ goTo, pricingDropdownVisible, togglePricingDropdown }) => {
  const ref = React.useRef(null)
  useClickAway(
    ref,
    () => {
      if (pricingDropdownVisible) {
        togglePricingDropdown()
      }
    },
    ['click']
  )

  if (!pricingDropdownVisible) return null

  return (
    <PricingDropdownWrapper ref={ref}>
      <PricingDropdownItem onClick={() => goTo(`/pricing/nonprofits`)}>
        Nonprofits
      </PricingDropdownItem>
      <PricingDropdownItem onClick={() => goTo(`/pricing/business`)}>Business</PricingDropdownItem>
      <PricingDropdownItem onClick={() => goTo(`/pricing/education`)}>
        Education
      </PricingDropdownItem>
    </PricingDropdownWrapper>
  )
}

const determineButtonVisibilities = ({ hideSefCta, isDashboardPage, isLoggedIn }) => {
  if (isDashboardPage) {
    return { showAccessDashboardButton: false, showProfileMenu: true }
  }

  if (!isLoggedIn) {
    return { showAccessDashboardButton: !hideSefCta, showProfileMenu: false }
  }

  return { showAccessDashboardButton: !hideSefCta, showProfileMenu: !!hideSefCta }
}

const CenterCta = ({ goTo, isLoading, isLoggedIn, roleData }) => {
  if (isLoading) return null

  const hasGodAdminRole = roleData.some(r => r.role_type === constants.ROLES.GOD_MODE)
  const hasIcasaRole = roleData.some(r => r.role_type === constants.ROLES.DGMT_ICASA_ADMIN)
  const hasMnoRole = roleData.some(role => role.role_type === constants.ROLES.DGMT_MNO_ADMIN)
  const hasDgmtAdminRole = roleData.some(r => r.role_type === constants.ROLES.DGMT_ADMIN)

  const isPrivilegedRole = hasGodAdminRole || hasDgmtAdminRole || hasIcasaRole || hasMnoRole
  if (isPrivilegedRole) return null

  const buttonLabel = isLoggedIn ? 'Submit website' : 'Apply as PBO'
  const buttonPath = isLoggedIn ? '/new-website' : `/new-pbo`

  return (
    <NavBarContentItem justifyContent="center">
      <NavLinksWrapper>
        <div onClick={() => goTo(buttonPath)}>
          <NavLinkItem>
            <DgmtNewPboButton>{buttonLabel}</DgmtNewPboButton>
          </NavLinkItem>
        </div>
      </NavLinksWrapper>
    </NavBarContentItem>
  )
}

const NavBarComponent = ({
  hideSefCta,
  isDashboardPage,
  isLoading,
  isLoggedIn,
  discoverDropdownVisible,
  menuModalIsOpen,
  notifications,
  pageType,
  pricingDropdownVisible,
  roleData = [],
  toggleInternationalizationMenu,
  toggleDiscoverDropdown,
  toggleMenuModal,
  togglePricingDropdown,
  userData
}) => {
  const isHomepage = ['homePage', 'dgmtHomePage'].includes(pageType)

  // const isTermsPage = pageType === 'termsPage'
  // const isStoryPage = pageType === 'storyPage'
  // const isPrivacyPage = pageType === 'privacyPage'

  if (userData && userData.on_probation) {
    return <Redirect to={`/probation`} noThrow />
  }

  const goTo = link => {
    toggleMenuModal('close')
    navigate(link)
  }

  const { y } = useWindowScroll()
  const transparent = y < 85

  // disabled until we get the translations
  const translationsEnabled = false

  if (isDgmt) {
    return (
      <MainNavWrapper homepage={isHomepage} transparent={transparent}>
        <NavBar isLoading={isLoading} homepage={isHomepage} transparent={transparent}>
          <NavBarContent>
            <NavBarContentItem>
              <LogoLink onClick={() => goTo('/')}>
                <img src={dgmtLogo} width="44" height="44" alt="DGMT logo" />
              </LogoLink>
            </NavBarContentItem>

            <CenterCta
              goTo={goTo}
              isLoading={isLoading}
              isLoggedIn={isLoggedIn}
              roleData={roleData}
            />

            <NavBarContentItem justifyContent="flex-end">
              <SupportIcon isLoggedIn={isLoggedIn} menuModalIsOpen={menuModalIsOpen} />

              <NavBarRightSection>
                <ConditionalDisplay displayWhen={[translationsEnabled]}>
                  <TranslateIconContainer onClick={toggleInternationalizationMenu}>
                    <Translate />
                  </TranslateIconContainer>
                </ConditionalDisplay>

                <ProfileMenu
                  menuModalIsOpen={menuModalIsOpen}
                  notifications={notifications}
                  toggleMenuModal={toggleMenuModal}
                />
              </NavBarRightSection>
            </NavBarContentItem>
          </NavBarContent>
        </NavBar>

        <CookieHeader />
        <FlashMessage />
      </MainNavWrapper>
    )
  }

  if (isSef || isSefEmployee) {
    const isHidden = y > 85 && isHomepage

    const { showAccessDashboardButton, showProfileMenu } = determineButtonVisibilities({
      hideSefCta,
      isDashboardPage,
      isLoggedIn
    })

    return (
      <MainNavWrapper hidden={isHidden} homepage={isHomepage} transparent={transparent}>
        <NavBar isLoading={isLoading} homepage={isHomepage} transparent={transparent}>
          <NavBarContent>
            <LogoLink homepage={isHomepage} isSef onClick={() => goTo('/')}>
              <img src={logo} width="121" height="44" alt="Brownie Points logo" />
            </LogoLink>

            <NavBarRightSection>
              <ScrollToTop />

              <SupportIcon isLoggedIn={isLoggedIn} menuModalIsOpen={menuModalIsOpen} />

              {showProfileMenu && (
                <ProfileMenu
                  menuModalIsOpen={menuModalIsOpen}
                  notifications={notifications}
                  toggleMenuModal={toggleMenuModal}
                />
              )}

              {showAccessDashboardButton && (
                <SefNavButtonWrapper>
                  <SefNavButtonHint>Already using our platform?</SefNavButtonHint>

                  <SefNavButton onClick={() => navigate(`/dashboard`)}>
                    <SefNavButtonContents>
                      <SvgIconWrapper>
                        <StarFill />
                      </SvgIconWrapper>

                      <TitleText>Access dashboard</TitleText>
                    </SefNavButtonContents>
                  </SefNavButton>
                </SefNavButtonWrapper>
              )}
            </NavBarRightSection>
          </NavBarContent>
        </NavBar>

        <CookieHeader />
        <FlashMessage />
      </MainNavWrapper>
    )
  }

  return (
    <MainNavWrapper homepage={isHomepage} transparent={transparent}>
      <NavBar isLoading={isLoading} homepage={isHomepage} transparent={transparent}>
        <NavBarContent>
          <LogoLink onClick={() => goTo(isLoggedIn ? '/feed' : '/')}>
            <img src={logo} width="121" height="44" alt="Brownie Points logo" />
          </LogoLink>

          <NavLinksWrapper>
            <div
              onClick={() => {
                if (isLoggedIn) {
                  goTo(`/dashboard/stories/new?eventType=GoodDeed&chooseTemplate`)
                } else {
                  goTo(`/feed`)
                }
              }}
            >
              <NavLinkItem>
                <ChampionBtn>{isLoggedIn ? 'Share story' : 'Stories'}</ChampionBtn>
              </NavLinkItem>
            </div>

            <NavLinkItemWrapper onClick={() => toggleDiscoverDropdown()}>
              <NavLinkItem>Explore</NavLinkItem>

              <DiscoverDropdown
                goTo={goTo}
                discoverDropdownVisible={discoverDropdownVisible}
                toggleDiscoverDropdown={toggleDiscoverDropdown}
              />
            </NavLinkItemWrapper>

            <NavLinkItemWrapper onClick={() => togglePricingDropdown()}>
              <NavLinkItem>Solutions</NavLinkItem>

              <PricingDropdown
                goTo={goTo}
                pricingDropdownVisible={pricingDropdownVisible}
                togglePricingDropdown={togglePricingDropdown}
              />
            </NavLinkItemWrapper>
          </NavLinksWrapper>

          <NavBarRightSection>
            <ScrollToTop />

            <ProfileMenu
              menuModalIsOpen={menuModalIsOpen}
              notifications={notifications}
              toggleMenuModal={toggleMenuModal}
              userData={userData}
            />
          </NavBarRightSection>
        </NavBarContent>
      </NavBar>

      <CookieHeader />
      <FlashMessage />
    </MainNavWrapper>
  )
}

export default NavBarComponent
