import React from 'react'
import styled, { css } from 'styled-components'

const isDev = process.env.NODE_ENV === 'development'

// utils
import th from 'utils/themeHelper'

// assets
import { AccountCircle } from '@styled-icons/material'
import { Close } from '@styled-icons/evil/Close'

export const Divider = styled.div`
  border-top: solid 2px #f3f3f3;
  width: 100%;
`

export const MenuLabel = styled.div.withConfig({
  shouldForwardProp: prop => prop !== 'menuModalIsOpen'
})`
  align-items: center;
  display: flex;
  margin-left: 2px;
  margin-right: ${({ menuModalIsOpen }) => (menuModalIsOpen ? '0' : '8px')};
  padding-top: 2px;
`

export const NavNotificationCount = styled.div`
  color: ${th('text.error')};
  font-family: ${th('fonts.bold')};
  font-size: 13px;
  margin: 0 3px;
`

const NavNotificationWrapperBase = styled.div`
  align-items: center;
  display: flex;
  margin: 0 8px 0 0;

  svg {
    fill: ${th('text.error')};
    height: 15px;
    width: 15px;
  }
`

export const NavNotificationWrapper = styled(NavNotificationWrapperBase)`
  & + & {
    margin-left: 0; /* Change the margin if there is a sibling */
  }
`

export const NotificationIcon = styled.span`
  background: #ed1c24;
  border-radius: 50%;
  color: white;
  padding: 3px;
  position: absolute;
  right: 4px;
  top: 4px;

  ${({ menuItem }) => {
    if (menuItem) {
      return css`
        right: auto;
        left: auto;
        top: 0;
      `
    }
  }}
`

export const ProfileDropdown = styled.div.withConfig({
  shouldForwardProp: prop => !['hasSibling', 'firstSibling', 'hasNotifications'].includes(prop)
})`
  border: none;
  border-radius: ${({ hasSibling, firstSibling }) =>
    hasSibling ? '0 18px 18px 0' : firstSibling ? '18px 0 0 18px' : '18px'};
  display: flex;
  background-color: white;
  color: ${th('text.dark')};
  cursor: pointer;
  flex-flow: row nowrap;
  font-family: ${th('fonts.bold')};
  font-size: 18px;
  height: 35px;
  justify-content: space-between;
  padding-left: 12px;
  vertical-align: middle;
  width: ${({ hasNotifications }) => (hasNotifications ? 'auto' : isDev ? 'auto' : '108px')};

  @media screen and (min-width: 769px) {
    &:hover {
      background: radial-gradient(100% 100% at 100% 5%, #56ce74 0%, #318435 100%);
      color: white;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
      box-shadow: 0px 2px 2px rgba(45, 35, 66, 0.4), 0px 4px 4px -3px rgba(45, 35, 66, 0.3),
        inset 0px -2px 0px rgba(58, 65, 111, 0.5);
    }
  }

  &:hover ${NavNotificationCount} {
    color: white;
  }

  &:hover ${NavNotificationWrapperBase} {
    svg {
      fill: #e6573c;
    }
  }
`

const UserAvatar = styled.div`
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  height: 30px;
  margin-top: 1.5px;
  width: 30px;
`

export const CloseMenuIconWrapper = styled.div`
  border-radius: 50%;
  height: 30px;
  margin-right: 4px;
  margin-top: 1.5px;
  width: 30px;
`

export const ProfileDropdownSeparator = styled.div`
  background: ${th('borders.default')};
  height: 100%;
  margin: 0;
  width: 1px;
`

export const CloseMenuIcon = () => {
  return (
    <CloseMenuIconWrapper>
      <Close />
    </CloseMenuIconWrapper>
  )
}

export const ProfilePicture = ({ user }) => {
  if (user.avatar_url) {
    return <UserAvatar src={user.avatar_url} />
  }

  return <AccountCircle height="33" />
}

export const ProfileDropdownOptions = styled.div`
  align-content: space-around;
  background-color: white;
  display: flex;
  border: 1px solid ${th('borders.default')};
  border-radius: 8px;
  box-shadow: 3px 3px 10px;
  flex-flow: column nowrap;
  left: -160px;
  margin-top: 8px;
  min-width: 250px;
  position: absolute;
  z-index: 9999;
`

export const DropdownItem = styled.a`
  color: ${th('text.dark')};
  border-radius: ${props =>
    props.firstItem ? '8px 8px 0 0' : props.lastItem ? '0 0 8px 8px' : '0'};
  padding: ${props =>
    props.firstItem
      ? '20px 0 20px 30px'
      : props.lastItem
      ? '20px 0 20px 30px'
      : '15px 0 15px 30px'};

  &:hover {
    background: ${th('backgrounds.light')};
  }
`

export const ProfileWrapper = styled.div`
  position: relative;
  margin-right: 4px;
  margin-top: 1px;

  a {
    cursor: pointer;
  }
`

export const ProfileMenuWrapper = styled.div`
  display: flex;
`
