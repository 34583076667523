import React from 'react'
import { navigate } from '@reach/router'

// Styles
import {
  CloseMenuIcon,
  MenuLabel,
  NavNotificationCount,
  NavNotificationWrapper,
  ProfileDropdown,
  ProfileDropdownSeparator,
  ProfileMenuWrapper,
  ProfilePicture,
  ProfileWrapper
} from './styles'

// utils
import { getLocalStorageValues } from 'utils/localStorageManager'

// icons
import { BellFill } from '@styled-icons/bootstrap/BellFill'
import { TrophyFill } from '@styled-icons/bootstrap/TrophyFill'

const NotificationComponent = ({ notificationsCount }) => {
  if (!notificationsCount) return null

  return (
    <NavNotificationWrapper>
      <BellFill />
      <NavNotificationCount>{notificationsCount}</NavNotificationCount>
    </NavNotificationWrapper>
  )
}

const ChallengesComponent = ({ challengeCount }) => {
  if (!challengeCount) return null

  // needs re-thinking
  return null

  return (
    <NavNotificationWrapper>
      <TrophyFill />
      <NavNotificationCount>{challengeCount}</NavNotificationCount>
    </NavNotificationWrapper>
  )
}

const determineNotifications = ({ notifications }) => {
  if (!notifications) return 0
  if (!notifications.length) return 0

  return notifications.filter(n => n.status === 'Unread').length
}

const ProfileMenu = ({ menuModalIsOpen, notifications, toggleMenuModal, userData = {} }) => {
  const user = getLocalStorageValues()
  const notificationsCount = determineNotifications({ notifications })
  const challengeCount = userData.availableCheckinsCount || 0

  if (!notificationsCount) {
    return (
      <ProfileMenuWrapper>
        <ProfileDropdown
          aria-label="Profile Dropdown"
          hasNotifications={challengeCount || notificationsCount}
          onClick={toggleMenuModal}
        >
          <MenuLabel menuModalIsOpen={menuModalIsOpen}>
            {menuModalIsOpen ? 'CLOSE' : 'MENU'}
          </MenuLabel>

          <ProfileWrapper>
            {menuModalIsOpen ? <CloseMenuIcon /> : <ProfilePicture user={user} />}
          </ProfileWrapper>
        </ProfileDropdown>
      </ProfileMenuWrapper>
    )
  }

  return (
    <ProfileMenuWrapper>
      <ProfileDropdown
        aria-label="Profile Dropdown"
        firstSibling
        hasNotifications={challengeCount || notificationsCount}
        onClick={() => (notificationsCount ? navigate('/dashboard/notifications') : null)}
      >
        <ChallengesComponent challengeCount={challengeCount} />
        <NotificationComponent notificationsCount={notificationsCount} />
      </ProfileDropdown>

      <ProfileDropdownSeparator />

      <ProfileDropdown
        aria-label="Profile Dropdown"
        hasNotifications={challengeCount || notificationsCount}
        hasSibling
        onClick={toggleMenuModal}
      >
        <MenuLabel menuModalIsOpen={menuModalIsOpen}>
          {menuModalIsOpen ? 'CLOSE' : 'MENU'}
        </MenuLabel>

        <ProfileWrapper>
          {menuModalIsOpen ? <CloseMenuIcon /> : <ProfilePicture user={user} />}
        </ProfileWrapper>
      </ProfileDropdown>
    </ProfileMenuWrapper>
  )
}

export default ProfileMenu
