import React, { Component } from 'react'
import { connect } from 'react-redux'
import { navigate, Redirect } from '@reach/router'
import qs from 'qs'

// config
import l, { isGetZeroed, isSkipData, isDgmt, isSef, isSefEmployee } from 'config/localization'

// utils
import isLoggedIn from 'utils/auth'
import { getApiStatus } from 'utils/localStorageManager'

// redux modules
import * as authModule from '../../redux/modules/authentication'
import * as notificationsModule from '../../redux/modules/notifications'
import * as placesModule from '../../redux/modules/places'

// import partials
import PayfastPaymentScript from './PayfastPaymentScript'
import Seo from './Seo'

// load ModalsCanvas, Navbar synchronously to avoid scroll bug
// where Y scroll position has to be > 0 to show modal
import ModalsCanvas from 'components/Modals/ModalsCanvas'
import Navbar from 'components/Navbar'

// import styles
import { FeedbackIconLink, Main, Wrapper, SiteDownNotice } from './styles'

// Load components synchronously
import Footer from 'components/Footer'
import Tooltip from 'components/Tooltip'

// icons
import { Question } from '@styled-icons/evil'

// keyboard key codes
const ESCAPE_KEYCODE = 27
const ONE_KEYCODE = 49
const TWO_KEYCODE = 50
const THREE_KEYCODE = 51
const FOUR_KEYCODE = 52
const FIVE_KEYCODE = 53
const SIX_KEYCODE = 54

const getTitle = ogTitle => {
  if (isGetZeroed) return 'Get Zeroed'
  if (isSkipData) return 'Skip Data'
  if (isDgmt) return 'Social Innovation Register'
  return ogTitle || 'Brownie Points | Do good, better'
}

const getSuccessClosedUrl = () => {
  return window.location.search
    .replace('hasRedirected=true', '')
    .replace('pending=true', '')
    .replace('recurringSuccess=true', '')
    .replace('success=true', '')
    .replace(/\&$/, '')
}

const derivePages = () => {
  const isHomePage = window.location.pathname === '/'
  const isDocsPage = window.location.href.indexOf('/docs') > -1
  const isLoginPage = window.location.href.indexOf('action=sefLogin') > -1
  const isResetPasswordPage = window.location.href.indexOf('reset-password') > -1
  const isOnboardingPage = window.location.href.indexOf('action=employeeInviteToken') > -1
  const isContractPage = window.location.href.indexOf('/contract') > -1

  const isAllowedPage =
    isDocsPage ||
    isHomePage ||
    isLoginPage ||
    isResetPasswordPage ||
    isOnboardingPage ||
    isContractPage
  const isPasswordPage = isLoginPage || isResetPasswordPage

  return { isAllowedPage, isPasswordPage }
}

const DgmtHelpIcon = () => {
  if (!isDgmt) return null

  const loggedIn = isLoggedIn()

  return (
    <div
      style={{
        borderRadius: '50%',
        bottom: '40px',
        right: '20px',
        width: '50px',
        height: '50px',
        position: 'fixed',
        zIndex: '999'
      }}
    >
      <Tooltip
        position="left"
        content={
          loggedIn
            ? 'Click here for assistance or to submit feedback.'
            : 'Need help? Email us at hi@browniepoints.africa for assistance or to submit feedback.'
        }
        displayBlock
        forceShow
      >
        {loggedIn && (
          <FeedbackIconLink to={'/dashboard/feedback/new'}>
            <Question />
          </FeedbackIconLink>
        )}

        {!loggedIn && <Question />}
      </Tooltip>
    </div>
  )
}

class PageWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      discoverDropdownVisible: false,
      pricingDropdownVisible: false,
      scrollY: 0,
      storiesDropdownVisible: false,
      visibleModal: null
    }

    this.escFunction = this.escFunction.bind(this)
    this.closeAllModals = this.closeAllModals.bind(this)
    this.toggleDiscoverDropdown = this.toggleDiscoverDropdown.bind(this)
    this.toggleMenuModal = this.toggleMenuModal.bind(this)
    this.togglePricingDropdown = this.togglePricingDropdown.bind(this)
  }

  closeAllModals() {
    const urlSearch = getSuccessClosedUrl()
    const path = window.location.pathname + urlSearch
    navigate(path, { replace: true })

    this.setState({ visibleModal: null })

    if (typeof this.props.resetModalState === 'function') {
      this.props.resetModalState()
    }
  }

  toggleMenuModal(value) {
    const { visibleModal } = this.state
    const forceClose = value === 'close'
    this.setState({ visibleModal: visibleModal ? null : forceClose ? null : 'menu-modal' })

    const urlSearch = getSuccessClosedUrl()
    const path = window.location.pathname + urlSearch
    navigate(path, { replace: true })
  }

  togglePricingDropdown() {
    this.setState({ pricingDropdownVisible: !this.state.pricingDropdownVisible })
  }

  toggleDiscoverDropdown() {
    this.setState({ discoverDropdownVisible: !this.state.discoverDropdownVisible })
  }

  escFunction(event) {
    const loggedIn = isLoggedIn()

    // some keyboard shortcuts aren't for SEF
    const bpOnlyFeature = !isSef && !isSefEmployee

    // determine if there's any open modal
    const anyModalIsOpen = !!this.state.visibleModal || !!this.props.visibleModal
    const menuModalIsOpen = this.state.visibleModal === 'menu-modal'

    // Any modal that is currently open will be closed
    if (anyModalIsOpen && event.keyCode === ESCAPE_KEYCODE) {
      // first close modals
      this.closeAllModals()

      // then scroll to previous Y position
      window.scrollTo({ top: this.state.scrollY })

      return true
    }

    // No modal is open so escape should open the menu modal
    if (event.keyCode === ESCAPE_KEYCODE) {
      this.setState({ scrollY: window.scrollY, visibleModal: 'menu-modal' })
    }

    if (menuModalIsOpen && event.keyCode === ONE_KEYCODE) {
      // Keyboard shortcut for key '1'
      this.setState({ visibleModal: null })
      navigate(loggedIn ? `/dashboard` : '/login')
    }

    if (bpOnlyFeature && menuModalIsOpen && loggedIn && event.keyCode === TWO_KEYCODE) {
      // Keyboard shortcut for key '2'
      this.setState({ visibleModal: null })

      const { userData } = this.props
      const profilePath = userData.username ? `/@${userData.username}` : `/users/${userData.id}`

      navigate(profilePath)
    }

    if (bpOnlyFeature && menuModalIsOpen && loggedIn && event.keyCode === THREE_KEYCODE) {
      // Keyboard shortcut for key '3'
      this.setState({ visibleModal: null })
      navigate('/search/challenges')
    }

    if (bpOnlyFeature && menuModalIsOpen && event.keyCode === FOUR_KEYCODE) {
      // Keyboard shortcut for key '4'
      this.setState({ visibleModal: null })
      navigate(`/search/${l('ROUTE_OPPS')}`)
    }

    if (bpOnlyFeature && menuModalIsOpen && event.keyCode === FIVE_KEYCODE) {
      // Keyboard shortcut for key '5'
      this.setState({ visibleModal: null })
      navigate(`/search/nonprofits`)
    }

    if (bpOnlyFeature && menuModalIsOpen && event.keyCode === SIX_KEYCODE) {
      // Keyboard shortcut for key '6'
      this.setState({ visibleModal: null })
      navigate(`/search/companies`)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false)

    const { autologin, visibleModal } = qs.parse(location.search.replace('?', ''))

    if (visibleModal) {
      this.setState({ visibleModal })
    }

    if (!isLoggedIn() && autologin) {
      this.props.attemptAutoLogin({ signInToken: autologin })
    }

    if (isLoggedIn()) {
      this.props.fetchMyRoles()
      this.props.fetchNotifications()
    }
  }

  render() {
    const { discoverDropdownVisible, pricingDropdownVisible, storiesDropdownVisible } = this.state
    const {
      children,
      ogTitle,
      bgColor,
      notifications,
      ogImage,
      ogDescription,
      shareModalHostName,
      shareModalOppTitle,
      shareModalDescription,
      oppStatus,
      fetchPlaces,
      places = [],
      locationIsLoading,
      extraInfoModalOpts = {},
      signupSuccessModalOpts = {},
      includePayfastScript,
      userData,
      roleData,
      campaignApplicationOpts = {},
      anythingIsLoading,
      anythingIsSubmitting,
      pageType,
      toggleInternationalizationMenu
    } = this.props

    // need a better way to check if site is down
    const apiStatus = getApiStatus()
    const siteIsDown = apiStatus === 'down'
    if (siteIsDown && false) {
      return <SiteDownNotice />
    }

    const { isAllowedPage, isPasswordPage } = derivePages()

    if (isSefEmployee && !isAllowedPage) {
      return <Redirect to={`/dashboard`} noThrow />
    }

    if (isSef && !isAllowedPage) {
      return <Redirect to={`/dashboard`} noThrow />
    }

    const visibleModal = this.state.visibleModal || this.props.visibleModal
    const menuModalIsOpen = visibleModal === 'menu-modal'

    const loggedIn = isLoggedIn()
    const title = getTitle(ogTitle)
    const image = ogImage || window.location.origin + '/images/giving-tuesday.jpg'
    const description = ogDescription
      ? `${ogDescription.substring(0, 80)} ...`
      : 'Meaningful campaigns, relevant opportunities, more impact.'

    const isHomepage = ['homePage', 'dgmtHomePage'].includes(pageType)
    const navbarLoading = isHomepage ? false : anythingIsLoading || anythingIsSubmitting

    return (
      <div style={{ position: 'relative' }}>
        <Wrapper id="pageWrapper" visibleModal={visibleModal}>
          <DgmtHelpIcon />

          <Seo title={title} description={description} image={image} url={window.location} />
          <PayfastPaymentScript includePayfastScript={includePayfastScript} />

          <Navbar
            discoverDropdownVisible={discoverDropdownVisible}
            hideSefCta={isPasswordPage}
            isLoading={navbarLoading}
            isLoggedIn={loggedIn}
            notifications={notifications}
            roleData={roleData}
            menuModalIsOpen={menuModalIsOpen}
            pageType={pageType}
            pricingDropdownVisible={pricingDropdownVisible}
            storiesDropdownVisible={storiesDropdownVisible}
            toggleDiscoverDropdown={this.toggleDiscoverDropdown}
            toggleInternationalizationMenu={toggleInternationalizationMenu}
            toggleMenuModal={this.toggleMenuModal}
            togglePricingDropdown={this.togglePricingDropdown}
            userData={userData}
          />

          <Main bgColor={bgColor} visibleModal={visibleModal}>
            {children}
          </Main>

          {!anythingIsLoading && <Footer />}
        </Wrapper>

        <ModalsCanvas
          campaignApplicationOpts={campaignApplicationOpts}
          closeAllModals={this.closeAllModals}
          extraInfoModalOpts={extraInfoModalOpts}
          fetchPlaces={fetchPlaces}
          locationIsLoading={locationIsLoading}
          loggedIn={loggedIn}
          notifications={notifications}
          oppStatus={oppStatus}
          places={places}
          shareModalOppTitle={shareModalOppTitle}
          shareModalDescription={shareModalDescription}
          shareModalHostName={shareModalHostName}
          signupSuccessModalOpts={signupSuccessModalOpts}
          userData={userData}
          visibleModal={visibleModal}
        />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  fetchPlaces: (input, term) => dispatch(placesModule.fetchPlaces(input, term)),
  attemptAutoLogin: key => dispatch(authModule.attemptAutoLogin(key)),
  fetchNotifications: () => dispatch(notificationsModule.fetchNotifications()),
  fetchMyRoles: () => dispatch(authModule.fetchMyRoles()),
  logout: () => dispatch(authModule.logout())
})

const mapStateToProps = state => ({
  roleData: state.authentication.roleData || [],
  notifications: state.notifications.data,
  places: state.places.data.map(d => d.description),
  userData: state.authentication.userData,
  locationIsLoading: state.places.isLoading,
  anythingIsLoading:
    state.authentication.isLoading ||
    state.applications.isLoading ||
    state.campaigns.isLoading ||
    state.opportunities.isLoading ||
    state.organisations.isLoading ||
    state.people.isLoading,
  anythingIsSubmitting:
    state.authentication.isSubmitting ||
    state.applications.isSubmitting ||
    state.campaigns.isSubmitting ||
    state.opportunities.isSubmitting ||
    state.organisations.isSubmitting ||
    state.people.isSubmitting
})

export default connect(mapStateToProps, mapDispatchToProps)(PageWrapper)
